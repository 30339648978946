document.addEventListener("turbo:load", function(){
  const menu = document.querySelector('.menu');
  const submenuInners = document.querySelectorAll('.submenu-inner');
  const width = menu.offsetWidth;

  submenuInners.forEach((submenuInner) => {
    submenuInner.style.maxWidth = `${width}px`;
  });

  let openSearch = document.querySelector('.search-button');
  let closeSearch = document.querySelector('.close-btn');
  let searchBox = document.querySelector('.search')
  let bg = document.querySelector('.search-bg')
  let searchInput = document.querySelector('.search-input')

  openSearch.addEventListener('click', function() {
    searchBox.classList.add('active')
    bg.classList.add('active')
    searchInput.focus();
  })

  closeSearch.addEventListener('click', function() {
    searchBox.classList.remove('active')
    bg.classList.remove('active')
  })

  const langsButton = document.querySelector('.current-box-inner');
  const langMenu = document.querySelector('.lang-box');
  const body = document.querySelector('body');

  langsButton.addEventListener('click', function(event) {
    event.stopPropagation();

    langMenu.classList.toggle('active');
    langsButton.classList.toggle('rotate');
  });

  function bodyClickHandler() {
    if (langMenu.classList.contains('active')) {
      langMenu.classList.remove('active');
      langsButton.classList.remove('rotate');
    }
  }

  body.addEventListener('click', bodyClickHandler);

  // header mobile

  let headerMobile = document.querySelector('.mobile')
  let openMenu = document.querySelector('.open-menu-btn');
  let bgMobile = document.querySelector('.mobile-bg');
  let closeSubmenu = document.querySelector('.close-submenu');
  let allSubMenu = document.querySelectorAll('.mobile-submenu-wrapper');
  let otherIcons = document.querySelector('.buttons-box')
  let eventsLink = document.getElementById('events-link')

  let brand = document.getElementById('brand');
  let bridal = document.getElementById('bridal')
  let gowns = document.getElementById('gowns')
  let atelier = document.getElementById('atelier')
  let langMobile = document.getElementById('lang-mobile')

  let brandMenu = document.getElementById('brand-menu');
  let bridalMenu = document.getElementById('bridal-menu')
  let gownsMenu = document.getElementById('gowns-menu')
  let atelierMenu = document.getElementById('atelier-menu')
  let langMobileMenu = document.getElementById('lang-mobile-menu')

  openMenu.addEventListener('click', function(){
    headerMobile.classList.toggle('open')
    bgMobile.classList.toggle('open')
    otherIcons.classList.toggle('open')
    allSubMenu.forEach(function(wrapper) {
      wrapper.classList.remove('open');
    });
  })

  bgMobile.addEventListener('click', function(){
    headerMobile.classList.remove('open');
    bgMobile.classList.remove('open');
    otherIcons.classList.remove('open')
    closeSubmenu.classList.remove('display');
    openMenu.classList.remove('hide');
  })

  if (eventsLink) {
    eventsLink.addEventListener('click', function(){
      headerMobile.classList.remove('open');
      bgMobile.classList.remove('open');
      otherIcons.classList.remove('open')
      closeSubmenu.classList.remove('display');
      openMenu.classList.remove('hide');
    })
  }


  closeSubmenu.addEventListener('click', function(){
    allSubMenu.forEach(function(wrapper) {
      wrapper.classList.remove('open');
    });
    closeSubmenu.classList.remove('display');
    openMenu.classList.remove('hide');
  })

  brand.addEventListener('click', function() {
    brandMenu.classList.add('open');
    closeSubmenu.classList.add('display');
    openMenu.classList.add('hide');
  })

  bridal.addEventListener('click', function() {
    bridalMenu.classList.add('open');
    closeSubmenu.classList.add('display');
    openMenu.classList.add('hide');
  })

  gowns.addEventListener('click', function() {
    gownsMenu.classList.add('open');
    closeSubmenu.classList.add('display');
    openMenu.classList.add('hide');
  })

  atelier.addEventListener('click', function() {
    atelierMenu.classList.add('open');
    closeSubmenu.classList.add('display');
    openMenu.classList.add('hide');
  })

  langMobile.addEventListener('click', function() {
    langMobileMenu.classList.add('open');
    closeSubmenu.classList.add('display');
    openMenu.classList.add('hide');
  })

  // for accordion

  var currentURL = window.location.href;

  if (!currentURL.includes("/faq")) {

    const headers = document.querySelectorAll('.accordion-item-header');

    headers.forEach(function (header) {
      header.addEventListener('click', function () {
        toggleAccordion(this);
      });
    });

    function toggleAccordion(element) {
      const item = element.closest('.accordion-item');
      const itemBody = item.querySelector('.accordion-item-body');

      item.classList.toggle('active');

      if (item.classList.contains('active')) {
        itemBody.style.maxHeight = itemBody.scrollHeight + "px";
        item.style.paddingBottom = "20px";
      } else {
        itemBody.style.maxHeight = null;
        item.style.paddingBottom = "10px";
      }
    }
  }

  var searchMobileBtn = document.querySelector('.button-search')
  var searchMobile = document.querySelector('.search-mobile-box')
  var searchMobileInp = document.querySelector('.search-mobile')

  searchMobileBtn.addEventListener('click', function() {
    searchMobile.classList.add('active')
    searchMobileInp.focus();
    bgMobile.classList.add('open')
  })

  bgMobile.addEventListener('click', function() {
    searchMobile.classList.remove('active')
  })

  openMenu.addEventListener('click', function(){
    if (searchMobile.classList.contains('active')) {
      searchMobile.classList.remove('active');
      bgMobile.classList.add('open');
    }
  })



  // on scroll

  let lastScrollTop = 100;
  const headerBottom = document.querySelector('.header-bottom');

  window.addEventListener('scroll', () => {
    const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (currentScrollTop > lastScrollTop) {
      headerBottom.classList.add('scrolling');
    } else {
      headerBottom.classList.remove('scrolling');
    }

    if (currentScrollTop < 50) {
      headerBottom.classList.remove('scrolling');
    }

    lastScrollTop = currentScrollTop;
  });



  var logOutMenu = document.querySelector('.user-info-btn');

  if (logOutMenu) {
    document.addEventListener('click', function(event) {
      if (!logOutMenu.contains(event.target)) {
        logOutMenu.classList.remove('open');
      }
    });

    logOutMenu.addEventListener('click', function(event) {
      logOutMenu.classList.toggle('open');
    });
  }
});

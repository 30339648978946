import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

// Connects to data-controller="checkout-delivery"
export default class extends Controller {
  static targets = ["radio"]

  toggle() {
    const selectedMethod = this.radioTargets.find(radio => radio.checked).value;
    console.log(selectedMethod);
    let partial = '';
    if (selectedMethod === 'true')  { partial = ''; }
    if (selectedMethod === 'false') { partial = 'another_delivery_address'; }
    const formData = new FormData();
    formData.append("partial", partial);

    Rails.ajax({
      type: "post",
      url: "carts/update_delivery_address_partial",
      data: formData,
      accept: 'text/vnd.turbo-stream.html',
      success: (data) => {
        Turbo.renderStreamMessage(data)
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
}

import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

// Connects to data-controller="checkout-country"
export default class extends Controller {
    connect() {
        $(document).ready(() => {
            const targetNode = document.querySelector('#select2-order_country-container');

            if (!targetNode) return;

            const observerConfig = { childList: true, subtree: true };

            let previousText = targetNode.textContent.trim();

            const callback = (mutationsList) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        const currentText = targetNode.textContent.trim();
                        if (currentText !== previousText) {
                            previousText = currentText;

                            Rails.ajax({
                                type: "post",
                                url: "carts/update_country_option",
                                data: `country=${currentText}`,
                                accept: 'text/vnd.turbo-stream.html',
                                success: (data) => {
                                    Turbo.renderStreamMessage(data);
                                    setTimeout(() => {
                                        $('.select-region').select2();
                                    }, 200);
                                },
                                error: (error) => {
                                    console.error(error);
                                }
                            });
                        }
                    }
                }
            };

            const observer = new MutationObserver(callback);
            observer.observe(targetNode, observerConfig);
        });
    }
}

import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

// Connects to data-controller="payment-delivery-select"
export default class extends Controller {
    connect() {
        $('.select-country-two').select2();
        const targetNode = document.querySelector('#select2-order_delivery_country-container');

        if (!targetNode) return;

        const observerConfig = { childList: true, subtree: true };

        const callback = (mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    const currentText = targetNode.textContent.trim();
                    if (currentText && currentText !== "Select country") {
                        Rails.ajax({
                            type: "post",
                            url: "carts/update_delivery_country_option",
                            data: `country=${currentText}`,
                            accept: 'text/vnd.turbo-stream.html',
                            success: (data) => {
                                Turbo.renderStreamMessage(data);
                                setTimeout(() => {
                                    $('.select-region-two').select2();
                                }, 200);
                            },
                            error: (error) => {
                                console.error(error);
                            }
                        });
                    }
                }
            }
        };

        const observer = new MutationObserver(callback);
        observer.observe(targetNode, observerConfig);
    }
}

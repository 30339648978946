import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

// Connects to data-controller="checkout-ukraine-delivery"
export default class extends Controller {
  static targets = ["radio"]

  toggle() {
    const selectedMethod = this.radioTargets.find(radio => radio.checked).value;
    let partial = '';
    if (selectedMethod === 'nova_poshta') { partial = 'nova_poshta_postal_office'; }
    if (selectedMethod === 'self_pickup') { partial = 'self_pickup_boutique'; }
    const formData = new FormData();
    formData.append("partial", partial);

    Rails.ajax({
      type: "post",
      url: "carts/update_ukraine_delivery_partial",
      data: formData,
      accept: 'text/vnd.turbo-stream.html',
      success: (data) => {
        Turbo.renderStreamMessage(data)
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
}

import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  updateSelectedSize(event) {
    const selectElement = event.target;
    const selectedSize = selectElement.options[selectElement.selectedIndex].value;
    const stockString = selectElement.dataset.stock;
    const stockArray = JSON.parse(stockString);

    const selectedSizeElement = document.querySelector('.selected-size');
    selectedSizeElement.textContent = (stockArray.includes(parseInt(selectedSize))) ? 'In stock' : '';
  }

  toggleOnlyForPartners(event) {
    const productId = event.params.index
    Rails.ajax({
      type: "patch",
      url: `products/${productId}/toggle_only_for_partners`,
    });
  }

  toggleInStock(event) {
    const productId = event.params.index
    Rails.ajax({
      type: "patch",
      url: `products/${productId}/toggle_in_stock`,
    });
  }

  toggleInGeneral(event) {
    const productId = event.params.index
    const currentHostAndProtocol = window.location.protocol + '//' + window.location.host;

    Rails.ajax({
      type: "patch",
      url: `${currentHostAndProtocol}/admin/products/${productId}/toggle_available_in_general_products`,
    });
  }

  toggleInOnlineShop(event) {
    const productId = event.params.index
    const currentHostAndProtocol = window.location.protocol + '//' + window.location.host;

    Rails.ajax({
      type: "patch",
      url: `${currentHostAndProtocol}/admin/products/${productId}/toggle_available_in_online_shop`,
    });
  }

  updateSizeOptions(event) {
    const sizesGroupId = event.target.value
    const productId = event.target.dataset.id;

    Rails.ajax({
      type: "get",
      url: `/products/${productId}/update_size_options?sizes_group_id=${sizesGroupId}`,
      accept: 'text/vnd.turbo-stream.html',
      success: (data) => {
        document.querySelector('#line_item_form').innerHTML = data;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  updateOnlineShopSizeOptions(event) {
    const sizesGroupId = event.target.value
    const productId = event.target.dataset.id;

    Rails.ajax({
      type: "get",
      url: `/shop/products/${productId}/update_size_options?sizes_group_id=${sizesGroupId}`,
      accept: 'text/vnd.turbo-stream.html',
      success: (data) => {
        document.querySelector('#line_item_form').innerHTML = data;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
}

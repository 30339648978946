import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination, Thumbs, EffectFade } from 'swiper/modules';
import 'swiper/css';

document.addEventListener("turbo:load", function(){
  var swiperr = Swiper;
  var init = false;

  function swiperMode() {
    let mobile = window.matchMedia('(min-width: 0px) and (max-width: 650px)');
    if (mobile.matches) {
      init = false;
    } else {
      var swiper1 = new Swiper(".main-slider", {
        modules: [Autoplay, Pagination],
        loop: true,
        slidesPerGroup: 2,
        slidesPerView: 2,
        spaceBetween: 30,
        loopFillGroupBlank: false,
        speed: 2500,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
        },
        breakpoints: {
          900: {
            slidesPerView: 4,
            slidesPerGroup: 4,
          },
          750: {
            slidesPerView: 3,
            slidesPerGroup: 2,
          },
          650: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          }
        }
      })

      var swiper2 = new Swiper(".celebrities", {
        modules: [Autoplay, Pagination],
        loop: true,
        slidesPerGroup: 2,
        slidesPerView: 2,
        spaceBetween: 30,
        speed: 1500,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 3500,
        },
        breakpoints: {
          850: {
            slidesPerView: 3,
            slidesPerGroup: 2,
          },
        }
      });

      var swiper3 = new Swiper(".event", {
        modules: [Autoplay, Pagination],
        loop: true,
        slidesPerGroup: 1,
        slidesPerView: 2,
        spaceBetween: 60,
        speed: 1500,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
        },
        breakpoints: {
          850: {
            slidesPerView: 3,
            slidesPerGroup: 1,
          },
        }
      });

      var swiper10 = new Swiper(".published", {
        modules: [ Autoplay ],
        slidesPerView: 3,
        slidesPerGroup: 1,
        loop: true,
        spaceBetween: 55,
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
        },
        breakpoints: {
          800: {
            slidesPerView: 4,
            slidesPerGroup: 1,
          },
          1000: {
            slidesPerView: 5,
            slidesPerGroup: 1,
          }
        }
      });
    }
  }

  var swiper4 = new Swiper(".related-news-slider", {
    modules: [Autoplay, Pagination],
    loop: true,
    slidesPerGroup: 1,
    slidesPerView: 1,
    spaceBetween: 16,
    speed: 1500,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    },
    breakpoints: {
      370: {
        slidesPerView: 2,
      },
      700: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 30,
      },
    }
  });

  var swiper5 = new Swiper(".partners_stories-slider", {
    modules: [Autoplay, Pagination],
    loop: true,
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 20,
    initialSlide: 1,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    breakpoints: {
      550: {
        slidesPerView: 2,
      }
    }
  });

  swiper5.on('slideChange', function () {
    var activeIndex = swiper5.realIndex;
    setActiveSlide(activeIndex);
  });

  var slides = document.querySelectorAll('.swiper-slide');

  slides.forEach(function (slide, index) {
    slide.addEventListener('click', function () {
      swiper5.slideTo(index);
      setActiveSlide(index);
    });
  });

  function setActiveSlide(index) {
    slides.forEach(function (slide) {
      slide.classList.remove('swiper-slide-active');
    });
    slides[index].classList.add('swiper-slide-active');
  }



  var swiper6 = new Swiper(".instagram-slider", {
    modules: [Autoplay, Pagination],
    loop: true,
    slidesPerView: 1,
    spaceBetween: 16,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    },
    breakpoints: {
      420: {
        slidesPerView: 2,
      },
      650: {
        slidesPerView: 3,
        spaceBetween: 30,
      }
    }
  });

  var swiper7 = new Swiper(".wishlist-slider", {
    modules: [Pagination],
    loop: true,
    slidesPerView: 1,
    spaceBetween: 16,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      380: {
        slidesPerView: 2,
      },
      600: {
        slidesPerView: 3,
      }
    }
  });

  window.addEventListener('load', function() {
    swiperMode();
  });

  window.addEventListener('resize', function() {
    swiperMode();
  });

});

document.addEventListener("turbo:frame-render", function(){
  var swiper10 = new Swiper(".celebrities-brides-slider", {
    modules: [Navigation],
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    spaceBetween: 12,
    navigation: {
      nextEl: ".next-img-show",
      prevEl: ".prev-img-show",
    },
  });
})

var swiper8 = new Swiper(".left-box-gallery", {
  modules: [Navigation],
  slidesPerView: 3,
  slidesPerGroup: 1,
  spaceBetween: 12,
  navigation: {
    nextEl: ".next-img",
    prevEl: ".prev-img",
  },
  breakpoints: {
    431: {
      direction: "vertical",
    }
  }
});

var swiper9 = new Swiper(".left-box-show-image", {
  modules: [ Thumbs, EffectFade, Navigation ],
  effect: "fade",
  thumbs: {
    swiper: swiper8,
  },
  navigation: {
    nextEl: ".next-img-show",
    prevEl: ".prev-img-show",
  },
});

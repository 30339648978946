document.addEventListener("turbo:load", function(){
  var video = document.getElementById('video');

  if (video) {
    video.play().then(function() {
    }).catch(function(error) {
      console.error('Error playing video:', error);
    });
  }
});
